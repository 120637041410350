<template>
  <div class="coin-recharge-channel" v-if="step === 'channel'">
    <van-config-provider>
      <van-nav-bar :title="$t('title.pay')" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    <div class="recharge-content">
      <div class="recharge-info">
        <p class="recharge-amount">{{rechargeData.amountStr}} PHP</p>
        <p v-if="rechargeData.expired > 0 && !countDownFinish">
          <span>{{$t('notify.orderTimeout1')}}</span>
          <van-count-down :time="rechargeData.expired * 1000" @finish="countDownFinish = true" />
          <span>{{$t('notify.orderTimeout2')}}</span>
        </p>
        <p v-else class="red-text">{{$t('notify.timeOut')}}</p>
      </div>
      <template v-if="rechargeData.expired > 0 && !countDownFinish">
        <div class="form-wrap">
          <template v-for="channel in channelList">
            <div class="form-title">{{channel.name}}</div>
            <div :class="`channel-wrap ${channel.channelSn === activeChannel  ? 'active' : ''}`">
              <div
                v-for="networking in channel.net"
                :key="networking"
                :class="`channel-tag ${networking === activeNetworking ? 'active' : ''}`"
                @click="activeChannel = channel.channelSn, activeNetworking = networking"
              >{{networking}}</div>
            </div>
          </template>
        </div>
        <div class="page-button-wrap">
          <van-button
            size="large"
            type="primary"
            block
            @click="handlePayCoinRecharge"
            :disabled="!activeChannel && !activeNetworking"
          >{{$t('button.submit')}}</van-button>
        </div>
        <van-popup v-model:show="showPay" round :close-on-click-overlay="false">
          <div class="pay-dialog">
            <p>{{rechargeData.amountStr}} PHP ({{activeNetworking}})</p>
            <div v-if="jsBrige" class="pay-btn" @click="openUrl">{{$t('title.pay')}}</div>
            <a v-else class="pay-btn" :href="walletPayUrl" target="_blank" @click="goBack">{{$t('title.pay')}}</a>
            <van-icon class="dialog-close-icon" name="close" color="#969799" size="28" @click="goBack" />
          </div>
        </van-popup>
      </template>
    </div>
  </div>
  <div class="coin-recharge-qrcode" v-if="step === 'qrcode'">
    <van-config-provider>
      <van-nav-bar :title="$t('title.rechargeDetail')" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    <div class="qrcode-wrap">
      <div class="qrcode-header-bg"></div>
      <div class="qrcode-header">
        <span>{{rechargeData.sn}}</span>
        <span>{{rechargeData.createTimeStr}}</span>
      </div>
      <div class="qrcode-content">
        <div class="qrcode-coin">
          <span class="qrcode-coin-amount">{{rechargeData.amountStr}}</span>
          <span class="qrcode-coin-type">PHP</span>
        </div>
        <vue-qrcode :value="this.payUrl" :options="{ width: 220 }" />
        <div style="position: relative; margin-top: -20px; margin-bottom: 40px;">
          <span class="qrcode-coin-address">{{this.payUrl}}</span>
          <img class="copy-icon" src="/images/copy.png" @click="copy(this.payUrl)" />
        </div>
        <van-button
          size="large"
          type="primary"
          block
          @click="goBack"
        >{{$t('button.finish')}}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Toast } from 'vant';
import useClipboard from 'vue-clipboard3'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import i18n from '@/assets/lang/index';
import { payCoinRecharge, getCoinRechargeChannel } from "@/api";

export default {
  components: {
    [VueQrcode.name]: VueQrcode,
  },
  setup() {
    const { toClipboard } = useClipboard();
    const copy = async (text) => {
      try {
        await toClipboard(text);
        Toast(i18n.global.t('notify.copySuccess'));
      } catch (e) {
        console.error(e)
      }
    }
    return { copy };
  },
  data() {
    return {
      rechargeData: JSON.parse(localStorage.getItem('rechargeData')),
      channelList: [],
      activeChannel: '',
      activeNetworking: '',
      countDownFinish: false,
      step: 'channel',
      showPay: false,
      walletPayUrl: '',
      jsBrige: window.jsBridge,
    };
  },
  
  mounted() {
    this.fetchCoinRechargeChannel();
  },

  methods: {
    fetchCoinRechargeChannel() {
      getCoinRechargeChannel({
        sn: this.rechargeData.sn,
      }).then(res => {
        this.channelList = res.data;
      });
    },
    handlePayCoinRecharge() {
      payCoinRecharge({
        sn: this.rechargeData.sn,
        channelSn: this.activeChannel,
        net: this.activeNetworking,
      }).then(res => {
        if (res.data.type === 0) {
          this.payUrl = res.data.payUrl;
          this.payUrlImg = res.data.payUrlImg;
          this.step = 'qrcode';
        } else if (res.data.type === 1) {
          this.walletPayUrl = res.data.payUrl;
          this.showPay = true;
        }
      });
    },
    openUrl() {
      if (window.jsBridge) {
        window.jsBridge.invokeNative('openUrl', this.walletPayUrl, '');
        this.goBack();
      }
    },
    goBack() {
      this.$router.back();
    },
    // goBack() {
    //   if (this.step == 'channel') {
    //     this.$router.back();
    //   } else {
    //     this.step = 'channel';
    //   }
    // },
  },
};
</script>

<style lang="less">
.coin-recharge-channel {
  padding-bottom: 80px;
  .recharge-content {
    padding: 20px 16px;
  }
  .recharge-info {
    padding: 30px 16px;
    margin-bottom: 20px;
    font-size: 13px;
    text-align: center;
    background: #fff;
    border-radius: 6px;
    .recharge-amount {
      color: @primary-color;
      font-size:23px;
      font-weight:700;
      margin-bottom: 20px;
    }
    .van-count-down {
      display: inline-block;
      vertical-align: middle;
      color: red;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px;
    }
    .red-text {
      color: red;
    }
  }
  .form-wrap {
    padding: 0 15px 16px;
    background: #fff;
    border-radius: 6px;
    overflow: hidden;
    .form-title {
      margin: 16px 0;
      font-size: 14px;
      color: #28293D;
      font-weight: 400;
    }
  }
  .channel-wrap {
    display: flex;
    .channel-tag {
      min-width: 70px;
      padding: 0 6px;
      margin-right: 10px;
      line-height: 26px;
      font-size: 14px;
      color: #6F6F93;
      background: #F9FAFB;
      border: 1px solid #F9FAFB;
      border-radius: 2px;
      text-align: center;
      box-sizing: border-box;
    }
  }
  .channel-wrap.active .channel-tag.active {
    font-weight: 700;
    color: var(--van-primary-color);
    background: #fff;
    border: 1px solid var(--van-primary-color);
  }
  .page-button-wrap {
    padding: 16px 0;
    box-sizing: border-box;
  }
}
.coin-recharge-qrcode {
  .qrcode-wrap {
    margin: 16px 22px 10px;
    background: #fff;
    .qrcode-header-bg {
      height: 20px;
      margin-left: -10px;
      margin-right: -10px;
      border-radius: 10px;
      background-color: var(--van-primary-color);
    }
    .qrcode-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 14px 12px;
      margin-top: -10px;
      line-height: 17px;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      background: #fff;
    }
    .qrcode-content {
      font-size: 14px;
      color: #6F6F93;
      font-weight: 400;
      text-align: center;
      .qrcode-coin {
        position: relative;
        line-height: 27px;
        margin-bottom: -6px;
        &-amount {
          margin-right: 2px;
          font-size: 22px;
          color: var(--van-primary-color);
          font-weight: 600;
          vertical-align: middle;
        }
        &-type {
          font-size: 16px;
          vertical-align: middle;
        }
      }
    }
    .qrcode-coin-address { vertical-align: middle; }
    .copy-icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      vertical-align: middle;
    }
    .van-button {
      border-radius: 0 0 10px 10px;
    }
  }
}
.pay-dialog {
  position: relative;
  width: 300px;
  font-size: 16px;
  text-align: center;
  padding: 30px 10px;
  .pay-btn {
    display: inline-block;
    height: 20px;
    padding: 10px 30px;
    font-size: 16px;
    margin-top: 20px;
    color: #fff;
    background: var(--van-primary-color);
    border-radius: 20px;
  }
  .dialog-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.theme1 {
  .coin-recharge-channel {
    .recharge-info {
      border-radius: 9px;
    }
    .form-wrap {
      border-radius: 9px;
    }
    .channel-wrap {
      display: flex;
      .channel-tag {
        color: #a5a5a5;
        border-color: #a5a5a5;
        border-radius: 4px;
        background: transparent;
      }
    }
    .channel-wrap.active .channel-tag.active {
      font-weight: 700;
      color: var(--van-primary-color);
      border: 1px solid var(--van-primary-color);
    }
    .page-button-wrap {
      padding-top: 40px;
    }
    .van-button {
      width: auto;
      height: 36px;
      min-width: 120px;
      padding: 0 20px;
      border-radius: 25px;
      margin: 0 auto; 
    }
  }
  
}
</style>
